<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar size="40" :src="user.avatar" variant="secondary">
      <i
        v-if="
          (user && user.contact && user.contact.role === 'distributor') ||
            user.role === 'distributor'
        "
        class="fa fa-biking"
        style="font-size: 18px"
      ></i>
    </b-avatar>

    <!-- <span>{{ JSON.stringify(user) }}</span> -->

    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.contact ? user.contact.full_name : user.full_name }}
        <span
          v-if="user.contact && user.contact.chatTyping"
          class="card-text text-success"
        >
          escribiendo...
        </span>
      </h5>
      <p v-if="user.lastMessage" class="card-text text-truncate">
        {{ user.lastMessage.message }}
      </p>
    </div>
    <div class="chat-meta text-nowrap" v-if="user.contact">
      <small v-if="user.lastMessage" class="float-right mb-25 chat-time">
        {{
          formatDateToMonthShort(user.lastMessage.created_at, {
            hour: "numeric",
            minute: "numeric"
          })
        }}
      </small>
      <b-badge v-if="user.unread" pill variant="danger" class="float-right">
        {{ user.unread }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import useChat from "./useChat";

export default {
  components: {
    BAvatar,
    BBadge
  },
  props: {
    tag: {
      type: String,
      default: "div"
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  }
};
</script>

<style></style>
