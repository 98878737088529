<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.id"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
        >
          Iniciar conversación
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <div class="d-flex align-items-center">

              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :text="`${activeChat.name.charAt(0)} ${activeChat.surname ? activeChat.surname.charAt(0) : ''}`"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                badge-variant="success"
              />
              <h6 class="mb-0 mr-1">
                {{ activeChat.full_name }}
              </h6>
            </div>

            <!-- Contact Actions
            <div class="d-flex align-items-center">
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
                v-b-toggle.sidebar-search
              />
            </div>-->
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <b-overlay
            :show="loading_conversation"
            spinner-variant="primary"
            rounded="sm"
          >
            <chat-log
              :userLogged="user_state"
              :chat-data="chatMessagesData"
              :activeChat="activeChat"
            />
          </b-overlay>
        </vue-perfect-scrollbar>

        <!-- Message Input -->

        <b-form
          class="chat-app-form"
          @submit.prevent="formSubmit"
          style="position: relative"
        >
          <div style="position: absolute; top: -5px" class="text-danger" v-if="form.important">
            <b>¡IMPORTANTE!</b>
          </div>
          <b-input-group class="input-group-merge form-send-message mr-1">

            <b-form-input
              :style="`${form.important ? 'border-left: 5px solid crimson' : ''}`"
              v-model="form.message"
              placeholder="Escribe tu mensaje"
            />
            <b-input-group-append is-text style="position:relative;">
              <div style="position:absolute; left: -40px; z-index: 100" class="d-flex align-items-center">
                <b-dropdown dropup no-caret variant="link" toggle-class="text-decoration-none">
                  <template slot="button-content">
                    <feather-icon :color="`${form.important ? 'crimson' : 'primary'}`" size="24" icon="AlertCircleIcon"></feather-icon>
                  </template>
                  <b-dropdown-item @click="form.important = false">
                    <feather-icon icon="MessageCircleIcon"></feather-icon>
                    Mensaje Estandar
                  </b-dropdown-item>
                  <b-dropdown-item @click="form.important = true">
                    <feather-icon :color="`${form.important ? 'crimson' : 'primary'}`" icon="AlertCircleIcon"></feather-icon>
                    Mensaje Importante
                  </b-dropdown-item>
                </b-dropdown>

                <!--aqui iria el file upload-->

              </div>
            </b-input-group-append>
          </b-input-group>
          <b-overlay
            :show="loading_btn_send_message"
            spinner-variant="primary"
            rounded="sm"
          >
            <b-button
              variant="primary"
              type="submit"
              :disabled="!form.message || form.message == ''"
            >
              Enviar
            </b-button>
          </b-overlay>
        </b-form>
      </div>
    </section>

    <!-- Sidebar-->
    <portal to="content-renderer-sidebar-left">
      <b-overlay
        :show="loading_chats"
        spinner-variant="primary"
        rounded="sm"
      >
        <chat-left-sidebar
          :loading_chat="loading_chat"
          :chatsContacts="chats"
          :contacts="contacts"
          :userLogged="user_state"
          :active-chat-contact-id="currentChatOpen"
          :active-contact-id="currentContactOpen"
          @open-chat="selectChat"
          @open-contact="selectContact"
          @searchContact="filterListContacts"
        />
      </b-overlay>
    </portal>

    <!--modal buscar mensajes-->
    <!--
    <b-sidebar
      id="sidebar-search"
      title="Buscar mensajes"
      backdrop-variant="transparent"
      bg-variant="white"
      backdrop
      shadow
      right
    >
      <b-overlay :show="loading_search" rounded="sm">
        <div class="search-box chat-search-box">
          <div class="position-relative p-1">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Buscar..."
                v-model="searchMessage"
                @keyup.enter="getSearch"
              />
            </b-input-group>
          </div>
        </div>

        <div class="chat-users" style="padding: 0 !important">
          <div class="chat-conversation scrollBar">
              <ul class="list-unstyled">
                <div v-if="foundMessages">
                  <b-card
                    class="chat-widget"
                    no-body
                  >
                    <section class="chat-app-window">
                      <vue-perfect-scrollbar
                        ref="refChatLogPS"
                        :settings="perfectScrollbarSettings"
                        class="user-chats scroll-area"
                      >
                        <chat-log-search
                          :chat-data="foundMessages"
                          :userLogged="$store.state.app.user"
                          :activeChat="activeChat"
                        />
                      </vue-perfect-scrollbar>
                    </section>
                  </b-card>
                </div>
              </ul>
              <h5 class="font-size-12 text-muted text-center mb-3">{{searchLabel}}</h5>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    -->

  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BSidebar,
  BTabs,
  BTab,
  BFormGroup,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BOverlay,
  BInputGroupAppend,
  BFormCheckbox,
  BBadge
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import ChatLogSearch from './ChatLogSearch.vue'

import ChatService from '@/services/chat.service'
import moment from 'moment'
moment.locale('es');
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapState } from 'vuex'
import Echo from 'laravel-echo';
import config from '@/config.js'

export default {
  props: {
  },
  components: {
    BCard,
    BInputGroupPrepend,
    BCardText,
    BSidebar,
    BTabs,
    BTab,
    BFormCheckbox,
    BBadge,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BOverlay,
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
    ChatLogSearch
  },
  data() {
    return {
      currentContactOpen: 0,
      loading_chat: false,
      searchLabel: 'Buscar mensajes en el chat',
      searchMessage: '',
      loading_search: false,
      startListen: false,
      foundContacts: [],
      loading_btn_send_message: false,
      loading_btn: false,
      required,
      activeChat: {},
      contacts: [],
      chats: [],
      filterContacts: [],
      chatMessagesData: {},
      todayDate: moment().format('YYYY-MM-DD'),
      loading_chats: false,
      foundMessages: [],

      form: {
        message: '',
        important: false
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      loading_input: false,
      loading_conversation: false,
      currentChatOpen: null
    }
  },
  watch: {
    searchMessage(newVal) {
      if(newVal == '') {
        this.foundMessages = []
        this.searchLabel = 'Buscar mensajes en el chat...'
      }
    }
  },
  methods: {
    hour(date) {
      return moment(date).locale('es').format('h:mm a')
    },
    date(date) {
      return moment(date).locale('es').format('LL')
    },
    formatDate(date) {
      return moment(date).format('DD/MM h:mm a')
    },
    async getSearch() {
      if(!this.activeChat) {
          return;
      } else {
        try {
          this.searchLabel = 'Buscando mensajes...'
          let type = this.activeChat.email ? 'private' : 'group'
          this.loading_search = true
          const { data: res } = await ChatService.getSearch(this.searchMessage, this.activeChat.id, type)

          this.foundMessages = res.data.reverse()

          if(this.foundMessages.length == 0) {
            this.searchLabel = 'No se han encontrado mensajes...'
          } else {
            this.searchLabel = ''
          }

        } catch (error) {
          this.$snotify.error(error.message)
        }
        this.loading_search = false
      }
    },
    echo(userId) {
      const echo = new Echo({
        broadcaster: 'socket.io',
        host: config.socketUrl,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        },
      });

      echo.channel(`user.${userId}`)
      .listen('.new-message', (data) => {
        this.handleMessage(data.message)
      })
    },
    handleMessage(message) {
      const index = this.chats.findIndex(chat => chat.id == message.chat_id)
      if(index == -1) {
        this.chats.unshift(
          {
            id : message.chat_id,
            contact: message.user_sender,
            unread: 0,
            lastMessage: message
          }
        )
      } else {
        this.chats[index].unread += 1
        this.chats[index].lastMessage = message
      }

      if (this.activeChat && message.from == this.activeChat.id) {
        if (this.chatMessagesData[this.todayDate]) {
          this.chatMessagesData[this.todayDate].push(message)
        } else {
          if(Object.keys(this.chatMessagesData).length == 0) {
            this.chatMessagesData = {}
          }
          this.chatMessagesData[this.todayDate] = []
          this.chatMessagesData[this.todayDate].push(message)
        }
        this.updateUnreadCount(message.user_sender.id, false)
        this.scrollToBottomInChatLog()
      }

      this.chats = [...this.chats]
    },

    filterListContacts(search) {
      if (search != '') {
        const contacts = this.contacts.filter(e => e.full_name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        this.contacts = contacts
      } else {
        this.contacts = this.foundContacts
      }
    },
    async selectContact(contact) {
      this.form.message = ''
      this.chatMessagesData = {}
      this.currentContactOpen = contact.id
      try {
        this.loading_chat = true
        const { data: res } = await ChatService.getChat(contact.id)

        if (Array.isArray(res.data.messages)) {
          this.chatMessagesData = {}
        } else {
          this.chatMessagesData = res.data.messages
          this.updateUnreadCount(contact.id, true)
        }

        this.activeChat = contact

        if (res.data.chat_id) {
          this.currentChatOpen = res.data.chat_id
        } else {
          this.currentChatOpen = 'new'
        }

        this.scrollToBottomInChatLog()

        this.loading_chat = false

      } catch (error) {
        console.log(error)
        this.loading_chat = false
      }

    },
    async selectChat(data) {

      this.form.message = ''

      this.chatMessagesData = {}
      try {
        this.loading_chat = true
        const { data: res } = await ChatService.getMessages(data.chat_id)

        if (Array.isArray(res.data)) {
          this.chatMessagesData = {}
        } else {
          this.chatMessagesData = res.data
        }
        this.activeChat = data.contact
        this.currentChatOpen = data.chat_id

        this.scrollToBottomInChatLog()
        this.updateUnreadCount(data.contact.id, true)

        this.loading_chat = false

      } catch (error) {
        console.log(error)
        this.loading_chat = false
      }
    },
    updateUnreadCount(contact_id, reset) {
      this.chats = this.chats.map((chat) => {

        if(chat.contact.id !== contact_id) return chat;

        if(reset) {
          chat.unread = 0
        } else {
          chat.unread ++
        }

        return chat;
      })
      this.chats = JSON.parse(JSON.stringify(this.chats))
    },

    /*services post*/
    async formSubmit(e) {
      if (this.activeChat) {
        try {
          this.loading_btn_send_message = true
          const data = {
            reciever_id: this.activeChat.id,
            message: this.form.message,
            important: this.form.important,
            chat_id: this.currentChatOpen
          }
          const { data:res } = await ChatService.sendMessage(data)

          if (this.chatMessagesData[this.todayDate]) {
            this.chatMessagesData[this.todayDate].push(res.data.message)
          } else {
            if (Object.keys(this.chats).length == 0) {
              this.chatMessagesData = {}
            }
            this.chatMessagesData[this.todayDate] = []
            this.chatMessagesData[this.todayDate].push(res.data.message)
          }

          const chatIndex = this.chats.findIndex(e => e.id == res.data.message.chat_id)
          if (chatIndex !== -1) {
            this.chats[chatIndex].lastMessage = res.data.message
          } else {
            let chat = {
              id : res.data.message.chat_id,
              unread: 0,
              lastMessage: res.data.message
            }
            chat.contact = res.data.message.chat.from == this.user_state.id ? res.data.message.chat.user_reciever : res.data.message.chat.user_sender
            this.chats.push(chat)
            if (this.currentChatOpen == 'new') {
              this.currentChatOpen = res.data.message.chat_id
            }
          }

          this.chatMessagesData = { ...this.chatMessagesData }

          this.scrollToBottomInChatLog()

        } catch (error) {
          console.log(error)
          //this.$snotify.error(error.message)
        }
      }else{
        try {
          this.loading_btn_send_message = true
          const data = {
            contactId: this.activeChat.id,
            message: this.form.message,
            important: this.form.important
          }
          const { data: res } = await ChatService.sendGroupMessage(data)

          if(this.chatMessagesData[this.todayDate]) {
            this.chatMessagesData[this.todayDate].push(res.data)
          } else {
            if(Object.keys(this.chatMessagesData).length == 0) {
              this.chatMessagesData = {}
            }
            this.chatMessagesData[this.todayDate] = []
            this.chatMessagesData[this.todayDate].push(res.data)
          }

          const group = this.allChatGroups.findIndex(i => i.id == res.data.group_id)
          this.allChatGroups[group].lastMessage = `${res.data.user_sender.names}: ${res.data.content}`

          this.allChatGroups = [...this.allChatGroups]

          this.chatMessagesData = {...this.chatMessagesData}

          this.scrollToBottomInChatLog()

        } catch (error) {
          this.swal(error.message)
        }
      }
      this.form.message = '';
      this.form.important = false;
      this.loading_btn_send_message = false
    },

    /*services get*/
    async getContacts() {
      try {
				this.loading_data = true
				const { data: res } = await ChatService.getContacts()
        this.contacts = res.data
        this.foundContacts = res.data
			} catch (error) {
				console.log(error)
			}
    },
    async getChats(){
      try {
        this.loading_chats = true
        const { data: res } = await ChatService.getChats()

        this.chats = res.data.map(chat => {
          return {
            id : chat.id,
            contact: chat.from == this.user_state.id ? chat.user_reciever : chat.user_sender,
            unread: chat.unread,
            lastMessage: chat.last_message
          }
        })
/*
        if(this.$route.query.user_id) {
          this.openChat(this.$route.query.user_id)
        }*/
      } catch (error) {
        console.log(error)
      }
      this.loading_chats = false
    },
    /*helpers*/
    scrollToBottomInChatLog() {
      this.$nextTick(() => {
        this.$refs.refChatLogPS.$el.scrollTop = 9999999;
      });
    },
    toast(title, variant = 'success', icon = 'CheckCircleIcon') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
    swal(title, content = '') {
      this.$swal({
        title: title,
        text: content,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }
  },
  mounted() {
    this.getContacts()
    this.getChats()
    setTimeout(() => {
      this.echo(this.user_state.id)
    }, 1000)
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user
		}),
    messageInput() {
      return this.form.message
    },
    today() {
      return moment().locale('es').format('LL')
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.btnImportant {
  position: absolute;
  right: 0;
  top: -17px
}
.importantSign {
  color: crimson;
  font-weight: 900;
  position: absolute;
  font-size: 10px;
  left: 20px;
  top: -12px;
}
</style>
