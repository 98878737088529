import api from '../api'

class ChatService {

    static getContacts(search) {
      return api({
        url: 'chat-contacts-admin',
        method: 'get',
        params: { search }
      })
    }

    static getMessages(chatId) {
      return api({
        url: 'chat-messages-admin',
        params: { chat_id: chatId }
      })
    }

    static getGroupMessages(groupId) {
      return api({
        url: 'chat-group-messages',
        method: 'get',
        params: { groupId }
      })
    }

    static getChat(contact_id) {
      return api({
        url: 'chat-by-contact',
        params: { contact_id }
      })
    }

    static getChats() {
      return api({
        url: 'chats',
        method: 'get',
      })
    }

    static downloadFile(data) {
      return api({
            responseType: 'blob',
            url: 'chat-download-file',
            method: 'post',
            data
      })
    }

    static getGroups() {
      return api({
            url: 'chats-groups',
            method: 'get',
      })
    }

    static sendMessage(data) {
      return api({
        url: 'chats',
        method: 'POST',
        data
      })
    }

    static sendGroupMessage(data) {
      return api({
            url: 'chat-group-send',
            method: 'POST',
            data
      })
    }

    static createGroup(groupName, members) {
      return api({
            url: 'chat-create-group',
            method: 'post',
            params: { groupName, members }
      })
    }

    static deleteGroup(groupId) {
      return api({
            url: 'chat-delete-group',
            method: 'delete',
            params: { groupId }
      })
    }

    static updateGroup(groupName, groupId) {
      return api({
            url: 'chat-update-group',
            method: 'put',
            params: { groupName, groupId }
      })
    }

    static removeMember(member, group) {
      return api({
            url: 'chat-remove-member',
            method: 'delete',
            params: { member, group }
      })
    }

    static storeMember(contactId, groupId) {
      return api({
            url: 'chat-store-member',
            method: 'post',
            params: { contactId, groupId }
      })
    }

    static typingMessage(contactId, group) {
      return api({
            url: 'chat-typing-message',
            method: 'post',
            params: { contactId, group }
      })
    }

    static getSearch(search, contactId, type) {
      return api({
            url: 'search-messages',
            method: 'get',
            params: { search, contactId, type }
      })
    }


}

export default ChatService
