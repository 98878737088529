<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="30"
      :src="group.name"
      class="badge-minimal"
      variant="primary"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ group.name }}
      </h5>
      <p class="card-text text-truncate">
        {{ group.lastMessage }}
      </p>
    </div>
    <!--
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <b-badge
        v-if="user.unread"
        pill
        variant="danger"
        class="float-right"
      >
        {{ user.unread }}
      </b-badge>
    </div>-->
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    group: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style>

</style>
