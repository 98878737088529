<template>
  <div class="chats">
    <div
      class="chat"
    >
      <div class="chat-body" style="margin: 0 0 0 0">
        <div
          v-for="msgData in chatData"
          :key="msgData.id"
          class="chat-content"
          :style="
            `
              ${msgData.user_sender.id == userLogged.id ? 'float: right' : 'float: left; background: #fff; color: #000'}
            `
          "
        >
          <b-overlay
            :show="loading_conversation == msgData.id"
            spinner-variant="primary"
            rounded="sm"
          >
            <p v-if="msgData.important" class="importantText">¡IMPORTANTE!</p>
            <span :class="`font-weight-bold ${msgData.user_sender.id == userLogged.id ? 'text-white' : 'text-primary'}`">
              {{ msgData.user_sender.full_name }}
            </span>
            <span class="font-weight-bold">{{ formatDate(msgData.created_at, 'D/MM h:mm a') }}</span>
            <p v-if="!msgData.file" v-linkified>{{ msgData.content }}</p>
            <p v-else>
              <a
                :class="`${msgData.user_sender.id == userLogged.id ? 'text-secondary font-weigh-bold' : 'text-info' }`"
                href="javascript: void(0);"
                @click="downloadFile(msgData)"
              >
                {{ msgData.content }}
              </a>
            </p>
          </b-overlay>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar, BOverlay } from 'bootstrap-vue'
import moment from 'moment'
moment.locale('es');
import ChatService from '@/services/chat.service'

export default {
  components: {
    BAvatar,
    BOverlay
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    activeChat: {
      type: Object
    },
    userLogged: {
      type: Object
    }
  },
  data() {
    return {
      loading_conversation: false
    }
  },
  methods: {
    formatDate(date, format) {
			return moment(date).format(format)
		},
    async downloadFile(message){
      this.loading_conversation = message.id
      if(this.activeChat && this.activeChat.email) {
        try {
          const data = {
            messageId: message.id,
            group: false
          }
          const { data: res } = await ChatService.downloadFile(data)

          this.download(res)

        } catch (error) {
          this.$snotify.error(error.message)
        }
      } else {
        try {
          const data = {
            messageId: message.id,
            group: true
          }
          const { data: res } = await ChatService.downloadFile(data)

          this.download(res)

        } catch (error) {
          console.log(error)
        }
      }
      this.loading_conversation = false
    },
    download(data) {
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      let filename = ''

      link.setAttribute(
        'download',
        filename
      )
      document.body.appendChild(link)
      link.click()
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {

      let chatLog = props.chatData

      //const formattedChatLog = []
      //let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: '',
        messages: [],
      }

      for (let messages in chatLog) {
        console.log(chatLog[messages])
        chatLog[messages].forEach((message, i) => {
          if (props.userLogged.id === message.user_sender.id) {
            msgGroup.messages.push({
              msg: message.content,
              time: message.sent_date,
            })
          } else {
            //chatMessageSenderId = msg.senderId
            //formattedChatLog.push(msgGroup)
            msgGroup = {
              senderId: message.user_sender.id,
              messages: [{
                msg: message.content,
                time: message.sent_date,
              }],
            }
          }
        })
      }

      return msgGroup
    })

    console.log(formattedChatData)

    return {
      formattedChatData,
      profileUserAvatarImg: props.profileUserAvatar,
    }
  },
}
</script>

<style scoped>
  .importantText {
    color: crimson;
    font-weight: 900;
    text-align: right;
  }

</style>
