<template>
  <div class="sidebar-left" style="width: 100% !important">
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />-->

      <!-- Sidebar Content -->
      <div
        class="sidebar-content card"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div v-if="userLogged" class="sidebar-profile-toggle">
              <b-avatar
                v-if="!userLogged.photo"
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :text="`${userLogged.name.charAt(0)} ${userLogged.surname ? userLogged.surname.charAt(0) : ''}`"
                variant="primary"
                badge
                badge-variant="success"
              />
              <b-avatar
                v-else
                size="42px"
                variant="transparent"
                :src="userLogged.url_photo"
                class="badge-minimal"
                badge-variant="success"
                badge
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchContact"
                placeholder="Buscar contactos..."
              />
            </b-input-group>
          </div>
        </div>

        <b-tabs justified>
          <b-tab>
            <template #title>
              <feather-icon icon="MessageCircleIcon" />
              <span>Chats</span>
            </template>

            <b-card-text>
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="chat-user-list-wrapper list-group scroll-area"
              >
                <!-- Chats -->
                <ul class="chat-users-list chat-list media-list" style="height: calc(100vh - 200px)">
                  <b-overlay
                    :show="loading_chat"
                    spinner-variant="primary"
                    rounded="sm"
                  >
                    <chat-contact
                      v-for="chat in chatsContacts"
                      :key="chat.id"
                      :user="chat"
                      tag="li"
                      :class="{'active': activeChatContactId === chat.id}"
                      is-chat-contact
                      @click="$emit('open-chat', { chat_id: chat.id, contact: chat.contact})"
                    />
                  </b-overlay>
                </ul>
              </vue-perfect-scrollbar>
            </b-card-text>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Contactos</span>
            </template>

            <b-card-text>
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="chat-user-list-wrapper list-group scroll-area"
              >
                <!-- Contacts -->
                <ul class="chat-users-list contact-list media-list" style="height: calc(100vh - 200px)">
                  <b-overlay
                    :show="loading_chat"
                    spinner-variant="primary"
                    rounded="sm"
                  >
                    <chat-contact
                      v-for="contact in filteredContacts"
                      :key="contact.id"
                      :user="contact"
                      tag="li"
                      :class="{'active': activeContactId === contact.id}"
                      @click="$emit('open-contact', contact)"
                    />
                  </b-overlay>
                </ul>
              </vue-perfect-scrollbar>
            </b-card-text>
          </b-tab>

        </b-tabs>
      </div>

    </div>

  </div>
</template>

<script>
import {
  BOverlay, BAvatar, BInputGroup, BInputGroupPrepend, BFormInput, BTabs, BTab, BCardText, BButton
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'
import ChatItemGroup from './ChatItemGroup'

export default {
  components: {
    BOverlay,
    BButton,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VuePerfectScrollbar,
    ChatContact,
    UserProfileSidebar,
    BTabs,
    BTab,
    BCardText,
    ChatItemGroup
  },
  props: {
    loading_chat: {
      type: Boolean,
      default: false
    },
    userLogged: {
      type: Object
    },
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: false,
    },
    profileUserData: {
      type: Object,
      required: false,
    },
    profileUserMinimalData: {
      type: Object,
      required: false,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    activeContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    searchContact(newVal) {
      this.$emit('searchContact', newVal)
    },
  },
  data() {
    return {
      searchContact: ''
    }
  },
  computed: {
  },
  methods: {

  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = contact => contact.full_name.toLowerCase().includes(searchQuery.value.toLowerCase())
    const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))

    return {
      // Search Query
      searchQuery,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
